import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
                <h1>NOT FOUND</h1>
                <p>You just tried to load a page that does not exist... 
                  please let us know how we can help you.</p>
            </div>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
